import {
  OrderHistoryModel,
  Arguments,
} from 'api/orders/getOrderHistory/Models';
import { getOrderHistory } from 'api/orders/getOrderHistory';
import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { FilterInputs } from 'entries/orders';

type AsyncThunkOptions = {
  rejectValue: number;
};

export const setOrderHistorySearch = createAction<FilterInputs>(
  'orders/findOrder',
);

export const fetchOrdersHistory = createAsyncThunk<
  OrderHistoryModel,
  Arguments,
  AsyncThunkOptions
>(
  'orderHistory/fetchOrderHistory',
  async (queryParams, { rejectWithValue }) => {
    try {
      return await getOrderHistory(queryParams);
    } catch (error) {
      return rejectWithValue(error.response.status);
    }
  },
);

export const saveOrdersHistoryFilterStatus = createAction<boolean>(
  'orderHistory/saveOrdersHistoryFilterStatus',
);

export const clearOrderHistory = createAction(
  'orderHistory/clearOrderHistory',
);

export const setOrderHistoryFilter = createAction<FilterInputs>(
  'orderHistory/setOrderHistoryFilter',
);
