import { createAsyncThunk } from '@reduxjs/toolkit';
import { activateProductApi } from 'api/products/activateProduct';
import { RESPONSE_STATUS_500 } from 'config/constants';

interface ProductResponse {
  productId: number;
  productName: string;
  status: string;
}

export const changeProductStatus = createAsyncThunk<
  ProductResponse[],
  number[]
>('products/changeStatus', async (ids, { rejectWithValue }) => {
  try {
    const response = await activateProductApi(ids);
    return response;
  } catch (error) {
    const statusCode = (error as any)?.response?.status || RESPONSE_STATUS_500;
    return rejectWithValue(statusCode);
  }
});
