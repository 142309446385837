export const ENDPOINTS = {
  BRANDS_LIST: '/brands/',
  PRODUCT_LIST: '/products',
  ORDERS: '/orders',
  ORDERS_HISTORY: '/orders/history',
  ORDER_SINGLE_PRODUCT: '/orders/checkout',
  ORDERS_CLIENT: '/orders/client',
  ORDER_SINGLE_PRODUCT_VALIDATION: '/orders/validate',
  SIGN_IN: '/auth/login',
  CHANGE_USER_PASSWORD: '/auth/change-password',
  RESET_PASSWORD: '/auth/reset-forgotten-password',
  CONFIRM_NEW_PASSWORD: '/auth/confirm-forgotten-password',
  CATALOGS: '/catalogs',
  CLIENT_CATALOGS: '/catalogs/client/products',
  CLIENTS: '/clients',
  SUPPLIERS: '/suppliers',
  FILTER_LIST: '/filters',
  FILTER_COUNTRIES: '/filters/countries',
  FILTER_CURRENCIES: '/filters/currencies',
  FILTER_ACCOUNT_CURRENCIES: '/filters/account-currencies',
  FILTER_SUPPLIERS: '/filters/suppliers',
  FILTER_NON_INTGR_SUPPLIERS: '/filters/non-integrated-suppliers',
  FILTER_BRANDS: '/filters/brands',
  FILTER_CLIENTS: '/filters/clients',
  FILTER_INTEGRATION_TYPE: '/filters/integration-types',
  FILTER_COMMERCIAL_STRUCTURE: '/filters/commercial-structures',
  FILTER_STATUSES: '/filters/statuses',
  FILTER_PAYMENT_METHODS: '/filters/settlement-methods',
  FILTER_DISCOUNT_TYPES: '/filters/discount-types',
  FILTER_ACCOUNT_TYPE: '/filters/account-types',
  USER_ROLES: '/filters/user-roles',
  SHOPPING_CART_LIST: '/baskets',
  ORDER_CHECKOUT: '/baskets/checkout',
  ORDER_VALIDATION: '/baskets/validate-order',
  TRANSACTION_REPORT: '/transaction-report',
  ACCOUNT_HISTORY: '/transactions',
  XO_XO_SUPPLIER: '/supplier-integration/xoxo',
  INVOICES: '/invoices',
  ClientPortalInvoices: '/clientPortalInvoices',
  FUNDING: '/funding-requests',
  FUNDING_CLIENTS: '/funding-requests/client',
  INVENTORY: '/inventory',
  USERS: '/users',
  USERS_INVITE: '/registration/invite',
  USER_REGISTER: '/registration/register',
  EMAIL_CHECK: '/registration/is-email-free',
  CATALOG_INTEGRATION: 'catalogIntegration/update-catalog',
  ANALYTICS_REPORT: '/reports/report',
  ANALYTICS_PRODUCTS: '/reports/products',
  ENEBA: '/eneba',
  PRODUCTS: '/products',
  AVAILABLE_PRODUCTS: '/available-products',
  PRODUCT_PRICE: '/product-price',
  UNMAPPED_PRODUCT: '/unmapped-products',
  ENEBA_VALIDATE_ORDER: '/order/validate',
  ENEBA_ORDER_CHECKOUT: '/order/checkout',
  GAMIVO: '/gamivo',
  SUPPLIER_PRICES: '/supplier-prices',
  XERO: '/xero',
  INVENTORY_REPORTS: '/inventoryReport',
  SUPPLIER_RULES: '/supplierChangeRules',
  RESTORE_CATALOG_ITEMS: '/catalogs/restoreCatalogItems',
  REACTIVATE_PRODUCT: '/reactivate-product',
  CARDS_HISTORY: '/cards/getCards',
  CLIENT_REPORT: '/clientReport',
  BRAND_REPORT: '/brandReport',
  SUPPLIER_REPORT: '/supplierReport',
  CARDS_REPORT: '/cards/downloadCardsReport',
  BINANCE: '/binance',
  CATEGORY: '/category',
  FILTER_CATEGORIES: '/filters/categories',
  SETTINGS: '/settings',
  BACKGROUNDJOB: '/v1.0/BackgroundJob',
  G2A: '/g2a',
  G2A_ORDER_CHECKOUT: '/g2a/order/checkout',
  G2A_VALIDATE_ORDER: '/g2a/order/validate',
  PRODUCT_OFFERS: '/product-offers',
  BEST_SELLERS: '/best-sellers',
  BALANCE: '/balance',
  PRICING_SIMULATION: '/pricing-simulation',
  MAP_BRANDS: '/map-brands',
  PRODUCT_BUFFERING: '/ProductBuffering',
  GENERATE_CREDIT_NOTE: '/generate-xero-credit-note',
  DOWNLOAD_CREDIT_NOTE: '/download-xero-credit-note',
  SUPPLIER_INVOICE: '/SupplierInvoices',
  ENEBA_ACCOUNT_LIST: '/eneba-account-list',
  CURRENCY: '/Currency',
  UPDATE_G2A_UNMAPPED_PRODUCT: '/update-g2a-unmapped-products-by-ids',
  DELETE_G2A_UNMAPPED_PRODUCT: '/delete-g2a-unmapped-products-by-ids',
  RESTORE_CATALOG_ITEMS_PRODUCT: '/catalogs/resetCatalogItemsProduct',
  CLIENT_SETTING: '/clientSetting',
  AUTH: '/auth',
  BINANCE_OFFERS: '/BinanceOffers',
  KINGUIN: 'Kinguin',
  KINGUIN_ORDER_CHECKOUT: '/kinguin/order/checkout',
  KINGUIN_VALIDATE_ORDER: '/kinguin/order/validate',
  UPDATE_KINGUIN_UNMAPPED_PRODUCT: '/update-kinguin-unmapped-products-by-ids',
  MARKETPLACE_RECONCILATION: '/marketplaceReconcilation',
  CLIENT_CATALOGS_V2: '/catalogs-brands/client/brands',
  CATALOGBRANDS: '/catalogs-brands',
  RECONCILIATION: '/reconciliation',
  SUPPLIER_CARDS: '/supplier-cards',
  CARDS: '/cards',
  REPORTS: '/reports',
  BUFFERED_CARDS: '/bufferedCards',
  ENQUEUE_ORDER_ITEMS: '/reprocess-order-items',
  KINGUIN_BLOCK_REASONS: '/offer-block-reasons',
  UPDATE_SPA_STATUS: '/update-spa-status',
  KINGUIN_BULK_UPDATE_SPA: '/bulk-update-spa', 
};
