import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { changeProductStatus } from './actions';

interface Product {
  productId: number;
  productName: string;
  status: string;
}

interface ProductStatusState {
  data: Product[];
  isLoading: boolean;
  error: string | null;
}

const initialState: ProductStatusState = {
  data: [],
  isLoading: false,
  error: null,
};

const productStatusSlice = createSlice({
  name: 'productStatus',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(changeProductStatus.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(changeProductStatus.fulfilled, (state, action: PayloadAction<Product[]>) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(changeProductStatus.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string || 'Failed to reactivate product';
      });
  },
});

export const productStatus = productStatusSlice.reducer;
