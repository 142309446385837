import { USER_PERMISSIONS, USER_ROLES } from './index';
import { PERMISSIONS } from './userPermissions';
import { ROLES } from './userRoles';

export enum userRole {
  Admin = 1,
  User,
  FinanceManager,
  CustomerSupport,
  Sales,
  OperationsExecutive,
  AccountManager,
  MarketplaceManager,
  Tech,
  SuperAdmin = 13,
}

const {
  INDEX,
  ORDERS_LANDING,
  BRANDS_LANDING,
  CLIENTS_LANDING,
  SIGN_IN_LANDING,
  CATALOGS_LANDING,
  PRODUCTS_LANDING,
  SHOPPING_CARTS_LANDING,
  BRAND_INFORMATION,
  SUPPLIERS_LANDING,
  CLIENT_INFORMATION,
  CATALOG_INFORMATION,
  SUPPLIER_INFORMATION,
  PAGE_NOT_FOUND,
  RESET_PASSWORD_LANDING,
  ORDER_INFORMATION_LANDING,
  CREATE_NEW_PASSWORD_LANDING,
  RESET_PASSWORD_LINK_EXPIRED_LANDING,
  BRAND_PRODUCTS,
  CLIENT_CATALOG,
  CATALOG_CLIENT,
  SHOPPING_CARTS_INFORMATION,
  XO_XO_SUPPLIER_CHECK,
  INVOICES,
  FUNDINGS,
  INVENTORY_INFORMATION,
  USERS_LANDING,
  USER_DETAILS,
  USER_INFORMATION,
  ANALYTICS,
  FINANCE_OPERATION,
  MAINTENANCE,
  ENEBA,
  RECONCILIATION,
  CLIENT_HOME,
  GAMIVO,
  INVENTORY_REPORTS,
  INVENTORY_REPORT_INFORMATION,
  PRODUCT_RULES,
  CLIENT_REPORT,
  SUPPLIER_REPORT,
  BRAND_REPORT,
  RESTORE_CATALOG,
  CARDS_LANDING,
  INVENTORY_INVOICES,
  CATEGORIES_LANDING,
  ENEBA_AUTO_MAP,
  BINANCE_REQUESTS,
  SETINGS,
  G2A,
  INVENTORY_STATISTICS,
  INVENTORY_CARDS,
  KINGUIN,
  RECONCILIATION_PROCESS,
  BAMBOO_BRANDS_LANDING,
  BAMBOO_CATALOGS_LANDING,
  BAMBOO_CATALOG_INFORMATION,
  SUPPLIER_ORDER,
  REPORT_GENERATION,
  BUFFER_HISTORY,
  REACTIVATE_PRODUCT
} = PERMISSIONS;

const {
  ADMIN,
  USER,
  FINANCE_MANAGER,
  CUSTOMER_SUPPORT,
  SALES,
  ACCOUNT_MANAGER,
  MARKETPLACE_MANAGER,
  TECH,
  OPERATIONS_EXECUTIVE,
} = ROLES;

export const RULES: {
  [key in USER_ROLES]: {
    static: USER_PERMISSIONS[];
    dynamic?: USER_PERMISSIONS[];
  };
} = {
  [ADMIN]: {
    static: [
      INDEX,
      BRANDS_LANDING,
      BRAND_PRODUCTS,
      BRAND_INFORMATION,
      ORDERS_LANDING,
      ORDER_INFORMATION_LANDING,
      PRODUCTS_LANDING,
      SHOPPING_CARTS_LANDING,
      SHOPPING_CARTS_INFORMATION,
      CLIENT_CATALOG,
      CLIENTS_LANDING,
      CLIENT_INFORMATION,
      CATALOGS_LANDING,
      CATALOG_INFORMATION,
      SUPPLIERS_LANDING,
      SUPPLIER_INFORMATION,
      PAGE_NOT_FOUND,
      XO_XO_SUPPLIER_CHECK,
      INVOICES,
      FUNDINGS,
      INVENTORY_INFORMATION,
      USERS_LANDING,
      USER_DETAILS,
      USER_INFORMATION,
      ANALYTICS,
      FINANCE_OPERATION,
      MAINTENANCE,
      ENEBA,
      RECONCILIATION,
      RECONCILIATION_PROCESS,
      GAMIVO,
      INVENTORY_REPORTS,
      INVENTORY_REPORT_INFORMATION,
      PRODUCT_RULES,
      CLIENT_REPORT,
      SUPPLIER_REPORT,
      BRAND_REPORT,
      RESTORE_CATALOG,
      CARDS_LANDING,
      INVENTORY_INVOICES,
      BINANCE_REQUESTS,
      CATEGORIES_LANDING,
      ENEBA_AUTO_MAP,
      SETINGS,
      G2A,
      INVENTORY_STATISTICS,
      INVENTORY_CARDS,
      KINGUIN,
      BAMBOO_BRANDS_LANDING,
      BAMBOO_CATALOGS_LANDING,
      BAMBOO_CATALOG_INFORMATION,
      SUPPLIER_ORDER,
      REPORT_GENERATION,
      BUFFER_HISTORY,
      REACTIVATE_PRODUCT
    ],
  },
  [USER]: {
    static: [
      INDEX,
      SIGN_IN_LANDING,
      USER_INFORMATION,
      PAGE_NOT_FOUND,
      RESET_PASSWORD_LANDING,
      CREATE_NEW_PASSWORD_LANDING,
      RESET_PASSWORD_LINK_EXPIRED_LANDING,
      ORDERS_LANDING,
      BRANDS_LANDING,
      SHOPPING_CARTS_LANDING,
      SHOPPING_CARTS_INFORMATION,
      ORDER_INFORMATION_LANDING,
      CATALOG_CLIENT,
      FINANCE_OPERATION,
      MAINTENANCE,
      CLIENT_HOME,
      FUNDINGS,
      BRAND_INFORMATION,
      REPORT_GENERATION,
    ],
  },
  [FINANCE_MANAGER]: {
    static: [
      INDEX,
      SIGN_IN_LANDING,
      USER_INFORMATION,
      PAGE_NOT_FOUND,
      RESET_PASSWORD_LANDING,
      CREATE_NEW_PASSWORD_LANDING,
      RESET_PASSWORD_LINK_EXPIRED_LANDING,
      ORDERS_LANDING,
      ORDER_INFORMATION_LANDING,
      ANALYTICS,
      FUNDINGS,
      FINANCE_OPERATION,
      INVOICES,
      MAINTENANCE,
      BRANDS_LANDING,
      BRAND_PRODUCTS,
      BRAND_INFORMATION,
      PRODUCTS_LANDING,
      CATALOGS_LANDING,
      CATALOG_INFORMATION,
      CLIENTS_LANDING,
      CLIENT_INFORMATION,
      INVENTORY_REPORTS,
      INVENTORY_REPORT_INFORMATION,
      CARDS_LANDING,
      SUPPLIERS_LANDING,
      SUPPLIER_INFORMATION,
      INVENTORY_INVOICES,
      CLIENT_REPORT,
      SUPPLIER_REPORT,
      BRAND_REPORT,
      INVENTORY_STATISTICS,
      RECONCILIATION_PROCESS,
      REPORT_GENERATION,
      BUFFER_HISTORY,
      REACTIVATE_PRODUCT
    ],
  },
  [CUSTOMER_SUPPORT]: {
    static: [
      INDEX,
      SIGN_IN_LANDING,
      PAGE_NOT_FOUND,
      RESET_PASSWORD_LANDING,
      CREATE_NEW_PASSWORD_LANDING,
      RESET_PASSWORD_LINK_EXPIRED_LANDING,
      BRANDS_LANDING,
      BRAND_PRODUCTS,
      BRAND_INFORMATION,
      ORDER_INFORMATION_LANDING,
      PRODUCTS_LANDING,
      SHOPPING_CARTS_LANDING,
      SHOPPING_CARTS_INFORMATION,
      CLIENT_CATALOG,
      CLIENTS_LANDING,
      CLIENT_INFORMATION,
      CATALOGS_LANDING,
      CATALOG_INFORMATION,
      SUPPLIERS_LANDING,
      SUPPLIER_INFORMATION,
      MAINTENANCE,
      USER_INFORMATION,
      ENEBA,
      GAMIVO,
      INVENTORY_REPORTS,
      INVENTORY_REPORT_INFORMATION,
      RESTORE_CATALOG,
      INVENTORY_INVOICES,
      ENEBA_AUTO_MAP,
      CARDS_LANDING,
      BINANCE_REQUESTS,
      INVENTORY_INFORMATION,
      G2A,
      INVENTORY_STATISTICS,
      INVENTORY_CARDS,
      CATEGORIES_LANDING,
      KINGUIN,
      BUFFER_HISTORY,
    ],
  },
  [SALES]: {
    static: [
      INDEX,
      CATALOGS_LANDING,
      BRANDS_LANDING,
      SIGN_IN_LANDING,
      USER_INFORMATION,
      PAGE_NOT_FOUND,
      RESET_PASSWORD_LANDING,
      CREATE_NEW_PASSWORD_LANDING,
      RESET_PASSWORD_LINK_EXPIRED_LANDING,
      ORDERS_LANDING,
      ORDER_INFORMATION_LANDING,
      ANALYTICS,
      MAINTENANCE,
      CLIENTS_LANDING,
      BRAND_INFORMATION,
      PRODUCTS_LANDING,
      CATALOG_INFORMATION,
      INVENTORY_INFORMATION,
      INVENTORY_CARDS,
      CLIENT_REPORT,
      BRAND_REPORT,
      REACTIVATE_PRODUCT
    ],
  },
  [ACCOUNT_MANAGER]: {
    static: [
      INDEX,
      BRANDS_LANDING,
      BRAND_INFORMATION,
      CATEGORIES_LANDING,
      PRODUCTS_LANDING,
      CATALOGS_LANDING,
      CATALOG_INFORMATION,
      INVENTORY_INFORMATION,
      INVENTORY_INVOICES,
      INVENTORY_STATISTICS,
      INVENTORY_REPORTS,
      INVENTORY_REPORT_INFORMATION,
      INVENTORY_CARDS,
      CLIENTS_LANDING,
      CLIENT_INFORMATION,
      ENEBA,
      ENEBA_AUTO_MAP,
      GAMIVO,
      G2A,
      CLIENT_REPORT,
      BRAND_REPORT,
      USERS_LANDING,
      USER_DETAILS,
      USER_INFORMATION,
      KINGUIN,
    ],
  },
  [OPERATIONS_EXECUTIVE]: {
    static: [
      INDEX,
      CATEGORIES_LANDING,
      BRANDS_LANDING,
      BRAND_INFORMATION,
      PRODUCTS_LANDING,
      INVENTORY_INFORMATION,
      INVENTORY_INVOICES,
      INVENTORY_STATISTICS,
      INVENTORY_REPORTS,
      INVENTORY_REPORT_INFORMATION,
      SUPPLIERS_LANDING,
      SUPPLIER_INFORMATION,
      CLIENTS_LANDING,
      CLIENT_INFORMATION,
      BRAND_REPORT,
      SUPPLIER_REPORT,
      USER_INFORMATION,
      SUPPLIER_ORDER,
      REPORT_GENERATION,
      REACTIVATE_PRODUCT
    ],
  },
  [MARKETPLACE_MANAGER]: {
    static: [
      INDEX,
      ORDERS_LANDING,
      ORDER_INFORMATION_LANDING,
      BRANDS_LANDING,
      BRAND_PRODUCTS,
      BRAND_INFORMATION,
      CATEGORIES_LANDING,
      PRODUCTS_LANDING,
      CATALOGS_LANDING,
      CATALOG_INFORMATION,
      INVENTORY_INFORMATION,
      INVENTORY_INVOICES,
      INVENTORY_STATISTICS,
      INVENTORY_REPORTS,
      INVENTORY_REPORT_INFORMATION,
      INVENTORY_CARDS,
      CLIENTS_LANDING,
      CLIENT_INFORMATION,
      ENEBA,
      ENEBA_AUTO_MAP,
      GAMIVO,
      G2A,
      CLIENT_REPORT,
      BRAND_REPORT,
      USERS_LANDING,
      USER_DETAILS,
      USER_INFORMATION,
      CARDS_LANDING,
      SUPPLIERS_LANDING,
      KINGUIN,
      BUFFER_HISTORY,
    ],
  },
  [TECH]: {
    static: [
      INDEX,
      BRANDS_LANDING,
      BRAND_PRODUCTS,
      BRAND_INFORMATION,
      ORDERS_LANDING,
      ORDER_INFORMATION_LANDING,
      SHOPPING_CARTS_LANDING,
      PRODUCTS_LANDING,
      CLIENT_CATALOG,
      CLIENTS_LANDING,
      CLIENT_INFORMATION,
      CATALOGS_LANDING,
      CATALOG_INFORMATION,
      SUPPLIERS_LANDING,
      SUPPLIER_INFORMATION,
      PAGE_NOT_FOUND,
      XO_XO_SUPPLIER_CHECK,
      INVOICES,
      FUNDINGS,
      INVENTORY_INFORMATION,
      USERS_LANDING,
      USER_DETAILS,
      USER_INFORMATION,
      ANALYTICS,
      FINANCE_OPERATION,
      MAINTENANCE,
      ENEBA,
      RECONCILIATION,
      RECONCILIATION_PROCESS,
      GAMIVO,
      INVENTORY_REPORTS,
      INVENTORY_REPORT_INFORMATION,
      PRODUCT_RULES,
      CLIENT_REPORT,
      SUPPLIER_REPORT,
      BRAND_REPORT,
      RESTORE_CATALOG,
      CARDS_LANDING,
      INVENTORY_INVOICES,
      BINANCE_REQUESTS,
      CATEGORIES_LANDING,
      ENEBA_AUTO_MAP,
      SETINGS,
      G2A,
      INVENTORY_STATISTICS,
      INVENTORY_CARDS,
      KINGUIN,
      SUPPLIER_ORDER,
      REPORT_GENERATION,
      BUFFER_HISTORY,
      REACTIVATE_PRODUCT
    ],
  },
};
