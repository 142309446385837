import { apiClient } from 'config/auth';
import { ENDPOINTS } from 'api/endpoints';

interface ProductResponse {
  productId: number;
  productName: string;
  status: string;
}

export const activateProductApi = async (
  ids: number[],
): Promise<ProductResponse[]> => {
  const response = await apiClient.put(`${ENDPOINTS.PRODUCTS}/change-status`, {
    ids,
    status: 'Live',
  });
  return response.data;
};
